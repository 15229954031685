import _ from "lodash";
import * as React from "react";
import { useCallback, useEffect, useMemo, useRef } from "react";
import classNames from "classnames";

import { NewTimerPicker } from "../NewTimerPicker/NewTimerPicker";
import { CustomEditor, SubtitleRangeElement } from "../../types";

import EditorService from "../../services/EditorService";
import MediaService from "../../services/MediaService";

import { useRecoilValue } from "recoil";
import {
  currentTimeState,
  directionState,
  isDisabledState,
} from "../../store/states";
import {
  useFocused,
  useSelected,
  useSlateStatic,
} from "@sumit-platforms/slate-react";
import { Node } from "@sumit-platforms/slate";
import { RangeCount } from "./RangeCount";
import RangeInfo from "../RangeInfo/RangeInfo";

import "./RangeSubtitles.scss";
import "./RangeMedia.scss";
import LoadingPlaceholder from "../Video/LoadingPlaceholder";

interface Props {
  attributes: any;
  children?: React.ReactNode;
  handleBlur?: (rangeIndex?: number) => void;
  startIndex?: number | null;
  renderIndex?: number | null;
  isPlaceholder?: boolean;
}

const RangeMedia = ({
  attributes,
  children,
  handleBlur,
  startIndex,
  renderIndex,
  isPlaceholder,
}: Props) => {
  if (!_.isNumber(startIndex) || !_.isNumber(renderIndex)) {
    throw new Error("startIndex and renderIndex must be numbers");
  }
  const editor = useSlateStatic() as CustomEditor;
  const disabled = useRecoilValue(isDisabledState);
  const direction = useRecoilValue(directionState);
  const currTime = useRecoilValue(currentTimeState);

  const isFocused = useSelected();
  const isEditorFocused = useFocused();

  const element = editor.children[
    (startIndex || 0) + (renderIndex || 0)
  ] as SubtitleRangeElement;
  const rangeRef = useRef<any>(null);
  const isFocusedPrevValue = useRef(false);

  const getShouldHideCaption = () => {
    if (!currTime) return true;
    if (!children) return true;
    if (editor.isContentScrolling) return false;

    if (isPlaceholder) return true;

    const timePassed =
      currTime > element.range.et || currTime < element.range.st;

    return timePassed;
  };

  const shouldHideCaption = getShouldHideCaption();

  const handleDoubleClick = useCallback(() => {
    EditorService.jumpToSlateWord(editor);
  }, [editor]);

  const onBlur = useCallback(() => {
    const rangeIndex = EditorService.getRangeIndex({ editor, element });
    const isChanged = EditorService.isRangeChanged({ element });
    if (handleBlur && isChanged) {
      handleBlur(rangeIndex);
    }
  }, [handleBlur]);

  const handleAddFrames = useCallback(
    (framesToAdd: number, position: "start" | "end") => {
      EditorService.handleAddFrames({
        editor,
        framesToAdd,
        position,
        rangeIndex: EditorService.getRangeIndex({ editor, element }),
        element,
        disabled,
      });
    },
    [disabled, editor, element]
  );

  useEffect(() => {
    if (isFocusedPrevValue.current && !isFocused) {
      onBlur();
    }

    isFocusedPrevValue.current = isFocused;
  }, [isFocused]);

  const focusMediaCaption = (e: React.MouseEvent) => {
    e.preventDefault();

    if (shouldHideCaption) return;

    const idx = EditorService.getRangeIndex({ editor, element });
    if (!_.isNumber(idx)) return;
    if (
      (editor.children[idx] as SubtitleRangeElement).type ===
      "subtitlePlaceholder"
    ) {
      return;
    }
    if (editor.selection?.anchor.path[0] !== idx) {
      editor.deselect();
      editor.select({
        anchor: { path: [idx], offset: 0 },
        focus: { path: [idx], offset: 0 },
      });
    }
  };

  return (
    <div
      className={classNames(`SlateRangeSubtitle MediaRange`, direction, {
        error: !_.isEmpty(element.range.validation?.errors),
        // warning: !_.isEmpty(range.validation?.warnings),
      })}
      onClick={focusMediaCaption}
      ref={rangeRef}
      {...attributes}
    >
      <div className="subtitleRangeTimes subtitles" contentEditable={false}>
        <NewTimerPicker
          className={classNames({
            overlapping:
              element.range.validation?.errors?.overlapping_prev ||
              element.range.validation?.errors?.start_after_end,
          })}
          isFocused={isFocused && isEditorFocused}
          value={element.range.st}
          step={MediaService.frameLength || 0.1}
          deductTime={() => handleAddFrames(-1, "start")}
          addTime={() => handleAddFrames(1, "start")}
          disabled={isPlaceholder || disabled}
          isPlaceholderPicker={isPlaceholder}
          useLiveTimeSetter={!isPlaceholder}
          position={"st"}
        />
        <NewTimerPicker
          className={classNames({
            overlapping:
              element.range.validation?.errors?.overlapping_next ||
              element.range.validation?.errors?.start_after_end,
          })}
          isFocused={isFocused && isEditorFocused}
          value={element.range.et}
          step={MediaService.frameLength || 0.1}
          deductTime={() => handleAddFrames(-1, "end")}
          addTime={() => handleAddFrames(1, "end")}
          disabled={isPlaceholder || disabled}
          isPlaceholderPicker={isPlaceholder}
          useLiveTimeSetter={!isPlaceholder}
          position={"et"}
        />
      </div>
      <div className={"rangeMediaContent"}>
        <LoadingPlaceholder />
        <div id={"videoPlayerContainer"} className={"videoContainer"}>
          <div
            className={classNames("videoSubtitles", {
              hide: shouldHideCaption,
              disabled,
            })}
            onDoubleClick={handleDoubleClick}
          >
            {children}
          </div>
          {!isPlaceholder && (
            <div className="mediaInfoContainer">
              <RangeCount index={startIndex + renderIndex + 1} />

              <RangeInfo
                validation={element.range.validation}
                text={Node.string(element)}
                isFocused={isFocused && isEditorFocused}
                direction={direction}
                disabled={disabled}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RangeMedia;
