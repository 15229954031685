import React, { useMemo } from "react";
import MediaService from "../../services/MediaService";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { mediaErrorState } from "../../store/states";
import { useRecoilValue } from "recoil";

const LoadingPlaceholder = () => {
  // const currentMediaSrc = MediaService.media.src;
  const mediaError = useRecoilValue(mediaErrorState);
  const isMediaLoadError = useMemo(() => {
    return mediaError[MediaService.media?.src];
  }, [mediaError]);

  const isMediaLoading = useMemo(() => {
    return MediaService?.media?.readyState === 0 && !isMediaLoadError;
  }, [MediaService?.media?.readyState]);

  return isMediaLoading && !isMediaLoadError ? (
    <div className={"videoPlaceholderWrapper"}>
      <FontAwesomeIcon
        icon={faSpinnerThird}
        spin
        color={"white"}
        className={"videoPlaceholder"}
        size={"3x"}
      />
    </div>
  ) : isMediaLoadError ? (
    <div className={"videoPlaceholderWrapper"}>
      <p className={"videoPlaceholder"}>Error loading media</p>
    </div>
  ) : null;
};

export default LoadingPlaceholder;
