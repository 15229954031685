import { SelectionTransforms } from "../interfaces/transforms/selection";
import { Range } from "../interfaces/range";
import { Editor } from "../interfaces/editor";
import { Transforms } from "../interfaces/transforms";

export const move: SelectionTransforms["move"] = (editor, options = {}) => {
  const { selection } = editor;
  const startIndex: number = (editor as any).startIndex;
  const currentIndex = selection?.anchor.path[0];
  const { distance = 1, unit = "character", reverse = false } = options;
  let { edge = null } = options;

  if (!selection) {
    return;
  }

  const { anchor, focus } = selection;
  const opts = { distance, unit, ignoreNonSelectable: true };

  const anchorText = Editor.string(editor, anchor.path);

  if (anchor.offset === 0 && reverse) {
    const isFirstRange =
      typeof startIndex === "number" &&
      typeof currentIndex === "number" &&
      startIndex - currentIndex === 0;
    if (isFirstRange) {
      // Prevent moving at the start of the node, stopping backward move
      return;
    }
  }

  if (anchor.offset === anchorText.length && !reverse) {
    const isLastRange =
      typeof startIndex === "number" &&
      typeof currentIndex === "number" &&
      currentIndex - startIndex === (editor as any).visibleRanges - 1;
    if (isLastRange) {
      // Prevent moving at the end of the node, stopping forward move
      return;
    }
  }

  if (edge === "start") {
    edge = Range.isBackward(selection) ? "focus" : "anchor";
  }

  if (edge === "end") {
    edge = Range.isBackward(selection) ? "anchor" : "focus";
  }

  const props: Partial<Range> = {};

  if (edge == null || edge === "anchor") {
    const point = reverse
      ? Editor.before(editor, anchor, opts)
      : Editor.after(editor, anchor, opts);

    if (point) {
      props.anchor = point;
    }
  }

  if (edge == null || edge === "focus") {
    const point = reverse
      ? Editor.before(editor, focus, opts)
      : Editor.after(editor, focus, opts);

    if (point) {
      props.focus = point;
    }
  }

  Transforms.setSelection(editor, props);
};
