import _ from "lodash";
import * as React from "react";
import { useEffect, useRef } from "react";
import classNames from "classnames";
import { CustomEditor, SubtitleRangeElement } from "../../types";

import { useRecoilValue } from "recoil";
import { currentTimeState, directionState } from "../../store/states";
import { useSlateStatic } from "@sumit-platforms/slate-react";

import "./RangeSubtitles.scss";
import { useKeyboardShortcuts } from "@sumit-platforms/ui-bazar/hooks";
import EditorService from "../../services/EditorService";
import { NewTimerPicker } from "../NewTimerPicker/NewTimerPicker";

interface Props {
  attributes: any;
  children?: React.ReactNode;
  startIndex?: number | null;
  renderIndex?: number | null;
}

const RangeSubtitlePlaceholder = ({
  attributes,
  children,
  startIndex,
  renderIndex,
}: Props) => {
  if (!_.isNumber(startIndex) || !_.isNumber(renderIndex)) {
    throw new Error("startIndex and renderIndex must be numbers");
  }

  const editor = useSlateStatic();
  const direction = useRecoilValue(directionState);
  const currTime = useRecoilValue(currentTimeState);
  const element = editor.children[
    (startIndex || 0) + (renderIndex || 0)
  ] as SubtitleRangeElement;

  const rangeRef = useRef<HTMLDivElement | null>(null);

  // useKeyboardShortcuts({
  //   handlers: {
  //     UNDO: (e) => {
  //       // eslint-disable-next-line no-debugger
  //       debugger;
  //       console.log("undo INSIDE");
  //     },
  //     REDO: (e) => {
  //       // eslint-disable-next-line no-debugger
  //       debugger;
  //       console.log("redo INSIDE");
  //     },
  //   },
  //   disabled: false,
  //   ref: document.querySelector(".SlateRangeSubtitle") as any,
  // });

  useEffect(() => {
    if (!_.isNumber(currTime)) return;
    if (currTime >= element.range.et || currTime < element.range.st) {
      const rangeIndex = EditorService.getCurrentIndexByElement(
        editor as CustomEditor,
        element
      );
      if (!_.isNumber(rangeIndex)) return;
      // Transforms.removeNodes(editor, {
      //   at: [rangeIndex],
      // });
    }
  }, [currTime]);

  return (
    <div
      className={classNames(`SlateRangeSubtitle`, direction, {
        // warning: !_.isEmpty(range.validation?.warnings),
      })}
      {...attributes}
      contentEditable={false}
    >
      <div className="subtitleRangeTimes subtitles">
        <NewTimerPicker
          className={classNames({
            ltr: direction === "ltr",
            overlapping:
              element.range.validation?.errors?.overlapping_prev ||
              element.range.validation?.errors?.start_after_end,
          })}
          isFocused={false}
          value={element.range.st}
          disabled={true}
          step={1}
          isPlaceholderPicker={true}
        />
        <NewTimerPicker
          className={classNames({
            ltr: direction === "ltr",
            overlapping:
              element.range.validation?.errors?.overlapping_next ||
              element.range.validation?.errors?.start_after_end,
          })}
          isFocused={false}
          value={element.range.et}
          disabled={true}
          step={1}
          isPlaceholderPicker={true}
        />
      </div>

      <div className="textContainer">
        <div className={classNames("rangeText disabled")} ref={rangeRef}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default RangeSubtitlePlaceholder;
