import React, { FC, useState, useEffect, useRef } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { TOptions } from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/pro-light-svg-icons";

import { RangeValidationResults } from "@sumit-platforms/types";

import "./RangeValidation.scss";
import Popover from "@mui/material/Popover";

interface Props {
  validation: RangeValidationResults;
  direction: "ltr" | "rtl";
}

const RangeValidationComponent: FC<Props> = ({ validation, direction }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div className={classNames("SlateRangeValidation", direction)}>
      {validation.errors && !_.isEmpty(validation.errors) && (
        <div
          className="validationsContainer errorsContainer"
          aria-owns={open ? "mouse-over-popover" : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          {/*<div className="validationBorder validationErrorsIcon">!</div>*/}
          <FontAwesomeIcon
            icon={faCircleExclamation}
            className={"validationBorder validationErrorsIcon exclamationIcon"}
          />
          <Popover
            id="mouse-over-popover"
            sx={{ pointerEvents: "none" }}
            open={open}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: direction === "rtl" ? "left" : "right",
            }}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <div className="rangeErrorsPopover validationsListPopover">
              {_.map(_.keys(validation.errors), (error) => (
                <div className="error" key={error}>
                  {t(
                    `validation_${error}`,
                    validation.errors && (validation.errors[error] as TOptions)
                  )}{" "}
                </div>
              ))}
              {/* {validation.warnings &&
              !_.isEmpty(validation.warnings) &&
              _.map(_.keys(validation.warnings), (warning) => (
                <div className="warning" key={warning}>
                  {t(`validation/${warning}`)}
                </div>
              ))} */}
            </div>
          </Popover>
        </div>
      )}

      {/* {validation.warnings &&
        !_.isEmpty(validation.warnings) &&
        (!validation.errors || _.isEmpty(validation.errors)) && (
          <div className="validationsContainer warningsContainer">
            <FontAwesomeIcon
              className="validationIcon validationWarningsIcon"
              icon={faInfoCircle}
            />

            <div className="rangewarnings validationsList">
              {_.map(_.keys(validation.warnings), (warning) => (
                <div className="warning" key={warning}>
                  {t(`validation_${warning}`)}
                </div>
              ))}
            </div>
          </div>
        )} */}
    </div>
  );
};

export default RangeValidationComponent;
