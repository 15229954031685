import React, {
  forwardRef,
  KeyboardEvent,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import {
  Editable,
  ReactEditor,
  RenderElementProps,
  RenderLeafProps,
  useSlate,
} from "@sumit-platforms/slate-react";
import EditorService from "./services/EditorService";
import { CustomEditor, MarkWordMetadata } from "./types";
import { useKeyboardShortcuts } from "@sumit-platforms/ui-bazar/hooks";
import {
  featureFlagsState,
  isDisabledState,
  repeatState,
} from "./store/states";
import { useRecoilState, useRecoilValue } from "recoil";
import { EditorForwardedRef } from "./SlateEditor";
import { ActionElement } from "./components/ActionElement/ActionElement";
import _ from "lodash";
import MediaService from "./services/MediaService";
import { FindAndReplace } from "./components/FindAndReplace/FindAndReplace";

import "./SubtitlesEditor.scss";

interface TranscriptEditorProps {
  className?: string;
  renderElement: (props: RenderElementProps) => JSX.Element;
  renderLeaf: (props: RenderLeafProps) => JSX.Element;
  onKeyDown: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  handleBlur: (rangeIndex?: number) => void;
  isFindAndReplaceOpen: boolean;
  toggleIsFindAndReplaceOpen: () => void;
  contentScroll?: any;
}

const SubtitlesEditor = (
  {
    renderElement,
    renderLeaf,
    onKeyDown,
    className,
    handleBlur,
    isFindAndReplaceOpen,
    toggleIsFindAndReplaceOpen,
    contentScroll,
  }: TranscriptEditorProps,
  ref: React.Ref<EditorForwardedRef>
) => {
  const editorController = useSlate() as CustomEditor;
  const featureFlags = useRecoilValue(featureFlagsState);
  const [_r, setRepeat] = useRecoilState(repeatState);

  const isDisabled = useRecoilValue(isDisabledState);

  const editorRef = useRef<HTMLElement | null>(null);

  const resetFindAndReplaceMarks = useCallback(() => {
    EditorService.removeMarks({
      editor: editorController,
      markKey: "highlightGreen",
    });

    // in subtitles, we want to run it twice
    EditorService.removeMarks({
      editor: editorController,
      markKey: "highlightGreen",
    });
  }, [editorController]);

  const afterFindAndReplaceDecoration = useCallback(
    (search: string, marks: MarkWordMetadata[] | null) => {
      resetFindAndReplaceMarks();
      EditorService.addMarks({
        editor: editorController,
        marks,
        markKey: "highlightGreen",
        search,
      });
    },
    [editorController, resetFindAndReplaceMarks]
  );

  const onReplaceOne = useCallback(
    (
      markedWord: MarkWordMetadata | null,
      newText: string,
      marks: MarkWordMetadata[]
    ) => {
      EditorService.replaceOne({
        editor: editorController,
        markedWord,
        newText,
        shouldUpdateRangeWords: false,
        mode: "subtitles",
        marks,
      });
    },

    [editorController]
  );
  const onReplaceAll = useCallback(
    (
      replaceInput: string,
      findInput: string,
      highlights: MarkWordMetadata[]
    ) => {
      return EditorService.replaceAll({
        editor: editorController,
        replaceInput,
        highlights,
        findInput,
        mode: "subtitles",
      });
    },
    [editorController]
  );

  useImperativeHandle(
    ref,
    () => ({
      editorRef: editorRef.current,
    }),
    [editorRef]
  );

  useEffect(() => {
    if (!editorController) return;
    try {
      const _editorRef = ReactEditor.toDOMNode(
        editorController,
        editorController
      );
      editorRef.current = _editorRef;

      if (_editorRef && _editorRef.scrollTop > 0) {
        _editorRef.scrollTo(0, 0);
      }
    } catch (e) {
      console.error("e :", e);
    }
  }, [editorController]);

  const handleJumpToNextRangeKeystroke = useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      EditorService.focusToNextOrPrevSubtitle(editorController, "next");
    },

    [editorController]
  );

  const handleJumpToPrevRangeKeystroke = useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      EditorService.focusToNextOrPrevSubtitle(editorController, "prev");
    },
    [editorController]
  );

  const handleGlitchTextToNextRange = useCallback(
    (e: KeyboardEvent) => {
      e.preventDefault();
      const entry = EditorService.getLastCursoredElement(editorController);
      if (!_.isNumber(entry?.path)) return;
      EditorService.glitchTextToNextRange(editorController, entry);
      handleBlur(entry.path);
    },
    [editorController, handleBlur]
  );

  const handleDeleteRange = useCallback(
    (e: KeyboardEvent) => {
      e.preventDefault();
      EditorService.deleteEntireRange(editorController);
    },
    [editorController]
  );

  const handleSkipToNextRangeKeystroke = useCallback(
    (e: KeyboardEvent) => {
      e.preventDefault();
      EditorService.onSkipSubtitle({ editor: editorController });
    },
    [editorController]
  );

  const handleSkipToPrevRangeKeystroke = useCallback(
    (e: KeyboardEvent) => {
      e.preventDefault();
      EditorService.onPrevSubtitle({ editor: editorController });
    },
    [editorController]
  );

  const handleRepeatKeystroke = useCallback(
    (e: KeyboardEvent) => {
      e.preventDefault();
      const repeat = MediaService.setIsRepeat(!MediaService.isRepeat);
      setRepeat(repeat);
    },
    [setRepeat]
  );

  const actionSectionElement = useMemo(() => {
    if (isFindAndReplaceOpen) {
      return (
        <FindAndReplace
          onReplaceOne={onReplaceOne}
          onReplaceAll={onReplaceAll}
          close={toggleIsFindAndReplaceOpen}
          afterTriggerDecorations={afterFindAndReplaceDecoration}
          onResetState={resetFindAndReplaceMarks}
          mode={"subtitles"}
        />
      );
    }
    return null;
  }, [
    resetFindAndReplaceMarks,
    afterFindAndReplaceDecoration,
    isFindAndReplaceOpen,
    onReplaceAll,
    onReplaceOne,
    toggleIsFindAndReplaceOpen,
  ]);

  const handleFindAndReplaceKeystroke = useCallback(
    (e: React.KeyboardEvent) => {
      if (isDisabled || !featureFlags.findAndReplace) return;
      if (isFindAndReplaceOpen) {
        toggleIsFindAndReplaceOpen();
      } else {
        e.preventDefault();
        toggleIsFindAndReplaceOpen();
      }
    },
    [isFindAndReplaceOpen, isDisabled, toggleIsFindAndReplaceOpen, featureFlags]
  );

  useKeyboardShortcuts({
    disabled: !featureFlags?.useNewKeyboardShortcuts,
    handlers: {
      JUMP_TO_NEXT_RANGE: handleJumpToNextRangeKeystroke,
      JUMP_TO_PREV_RANGE: handleJumpToPrevRangeKeystroke,
      GLITCH_TEXT_TO_NEXT_RANGE: handleGlitchTextToNextRange,
      DELETE_ENTIRE_RANGE: handleDeleteRange,
    },
    ref: editorRef.current,
  });

  useKeyboardShortcuts({
    disabled: !featureFlags?.useNewKeyboardShortcuts,
    handlers: {
      SKIP_TO_NEXT_SUBTITLE: handleSkipToNextRangeKeystroke,
      SKIP_TO_PREV_SUBTITLE: handleSkipToPrevRangeKeystroke,
      REPEAT: handleRepeatKeystroke,
      // FIND_AND_REPLACE: handleFindAndReplaceKeystroke, // hide from subtitles until stable
    },
  });

  return (
    <div className={"subtitlesEditorContent"} onKeyDown={onKeyDown}>
      <Editable
        className={className}
        readOnly={isDisabled}
        renderLeaf={renderLeaf}
        renderElement={renderElement}
        contentScroll={contentScroll}
      />
      <ActionElement hide={!actionSectionElement}>
        {actionSectionElement}
      </ActionElement>
    </div>
  );
};

export default forwardRef(SubtitlesEditor);
