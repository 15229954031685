import * as React from "react";
import _ from "lodash";
import isString from "lodash/isString";
import { FC, useState, useEffect, useRef, RefObject } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/pro-light-svg-icons";
import { faCirclePlus, faCircleMinus } from "@fortawesome/pro-solid-svg-icons";
import classNames from "classnames";
import { LiveTimeSetter } from "./LiveTimeSetter";
import { directionState } from "../../store/states";
import { useRecoilValue } from "recoil";
import { getTimecodeFromSeconds } from "@sumit-platforms/ui-bazar/utils";
import { JobTCOffsets } from "@sumit-platforms/types";

import "./NewTimerPicker.scss";

interface TimerBlockProps {
  value: string;
  type: string;
  step: number;
  min: string | number;
  max: string | number;
  selectAllClick?: boolean;
  arrowNav: (type: string, direction: string) => void;
  changeFocus: (key: string) => void;
  onChange: (newVal: string) => void;
  inputRef: RefObject<HTMLInputElement>;
  testid: string;
  isFocused?: boolean;
  isPlaceholderPicker?: boolean;
  disabled?: boolean;
}

const TimerBlock: FC<TimerBlockProps> = ({
  value,
  type,
  step,
  min,
  max,
  onChange,
  selectAllClick = true,
  arrowNav,
  changeFocus,
  inputRef,
  testid,
  isFocused,
  isPlaceholderPicker,
  disabled,
}) => {
  const [newValue, setNewValue] = useState<number | string>(value);
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    setNewValue(value);
  }, [value]);

  const calculateNewValue = (value: string) => {
    if (value === "0" || value === "00") {
      return value;
    }

    if (Number(value) < 0) {
      return max;
    }

    if (value > max) {
      return min;
    }

    if (
      Number(value) < 10 &&
      value.startsWith("0") &&
      value.toString().length > min.toString().length
    ) {
      return value.replace(/^0/g, "");
    }

    if (value.length > min.toString().length && value.startsWith("0")) {
      return value.replace(/^0/g, "");
    }

    return value;
  };

  const handleChange = (
    event:
      | React.KeyboardEvent
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | string
  ) => {
    const targetValue = isString(event)
      ? event
      : (event.target as HTMLTextAreaElement).value;
    const calculatedValue = calculateNewValue(targetValue);

    onChange(calculatedValue.toString());
    setNewValue(calculatedValue);
    setIsChanged(true);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    switch (event.nativeEvent.code) {
      case "Backspace":
        event.preventDefault();
        handleChange(min.toString());
        break;
      case "ArrowLeft":
        event.preventDefault();
        arrowNav(type, "left");
        break;
      case "ArrowRight":
        event.preventDefault();
        arrowNav(type, "right");
        break;
      default:
        changeFocus(event.nativeEvent.code);
        break;
    }
  };

  const handleBlur = () => {
    if (!isChanged) return;
    const stringValue = newValue.toString();
    let zeroPrefixedValue = newValue;
    if (stringValue.length < min.toString().length) {
      const gap = min.toString().length - stringValue.length;
      zeroPrefixedValue =
        Array.from({ length: gap }, () => "0").join("") + newValue;
      setNewValue(zeroPrefixedValue);
    }
    setIsChanged(false);
  };

  const handleFocus = () => {
    if (selectAllClick) {
      inputRef.current && inputRef.current.select();
    }
  };

  return (
    <input
      data-testid={testid}
      ref={inputRef}
      type={isPlaceholderPicker ? "text" : "number"}
      step={step}
      value={isPlaceholderPicker ? "--" : newValue}
      className={classNames(`TimerBlock ${type}`, {
        focused: isFocused,
        isPlaceholderPicker,
      })}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      onBlur={handleBlur}
      onFocus={handleFocus}
      disabled={disabled}
    />
  );
};

interface TimerPickerProps {
  className?: string;
  value: string | number;
  step: number;
  handleApprove?: (time: string) => void;
  handleCancel?: () => void;
  deductTime?: () => void;
  addTime?: () => void;
  offset?: number;
  fps?: number;
  disabled?: boolean;
  isFocused?: boolean;
  isPlaceholderPicker?: boolean;
  useLiveTimeSetter?: boolean;
  position?: "st" | "et";
  tcOffsets?: JobTCOffsets;
}

export const NewTimerPicker: FC<TimerPickerProps> = ({
  className,
  value,
  handleApprove,
  handleCancel,
  step = 0.1,
  fps = 25,
  addTime,
  deductTime,
  offset = 0,
  disabled = false,
  isFocused,
  isPlaceholderPicker,
  useLiveTimeSetter,
  position,
  tcOffsets = [[0, 0]],
}) => {
  const direction = useRecoilValue(directionState);
  const hoursRef = useRef<HTMLInputElement>(null);
  const minutesRef = useRef<HTMLInputElement>(null);
  const secondsRef = useRef<HTMLInputElement>(null);
  const framesRef = useRef<HTMLInputElement>(null);

  const [hours, setHours] = useState("0");
  const [minutes, setMinutes] = useState("0");
  const [seconds, setSeconds] = useState("0");
  const [frames, setFrames] = useState("0");

  useEffect(() => {
    let time = value as string;
    if (_.isNumber(value)) {
      time = getTimecodeFromSeconds(value + offset, { fps, tcOffsets });
    }
    parseTime(time);
  }, [offset, value]);

  const parseTime = (timeString: string) => {
    const [
      parsedHours = "00",
      parsedMinutes = "00",
      parsedSeconds = "00",
      parsedMillis = "00",
    ] = timeString.split(/[.:]/);

    setHours(parsedHours);
    setMinutes(parsedMinutes);
    setSeconds(parsedSeconds);
    setFrames(parsedMillis);
  };

  const stringifyTime = (
    hours: string,
    minutes: string,
    seconds: string,
    frames: string
  ) => {
    return `${hours}:${minutes}:${seconds}:${frames}`;
  };

  const arrowNav = (type: string, direction: string) => {
    switch (type) {
      case "hours":
        if (direction === "left") break;
        minutesRef.current && minutesRef.current.focus();
        break;
      case "minutes":
        if (direction === "left") {
          hoursRef.current && hoursRef.current.focus();
        } else {
          secondsRef.current && secondsRef.current.focus();
        }
        break;
      case "seconds":
        if (direction === "left") {
          minutesRef.current && minutesRef.current.focus();
        } else {
          framesRef.current && framesRef.current.focus();
        }
        break;
      case "frames":
        if (direction === "right") break;
        secondsRef.current && secondsRef.current.focus();
        break;
    }
  };

  const getUpdatedValue = React.useCallback(() => {
    return `${hours}:${minutes}:${seconds}:${frames}`;
  }, [hours, minutes, seconds, frames]);

  return (
    <div className={`NewTimerPickerWrapper ${direction}`}>
      {useLiveTimeSetter && <LiveTimeSetter position={position} />}
      <div className={classNames("NewTimerPicker", className, { disabled })}>
        <div className="timerContainer">
          {handleApprove && handleCancel && !disabled && (
            <div className="actionButtons">
              <FontAwesomeIcon
                className="icon approve"
                icon={faCheck}
                onClick={() => handleApprove(getUpdatedValue())}
              />
              <FontAwesomeIcon
                className="icon cancel"
                icon={faTimes}
                onClick={() => handleCancel()}
              />
            </div>
          )}
          <>
            <TimerBlock
              isFocused={isFocused}
              testid="hoursInput"
              inputRef={hoursRef}
              value={hours}
              type="hours"
              step={step * 10}
              min={"0"}
              max={9}
              arrowNav={arrowNav}
              changeFocus={(key: string) => {
                if (!key.startsWith("Arrow") && hours.length === 1) {
                  setTimeout(() => {
                    minutesRef.current?.focus();
                  }, 0);
                }
              }}
              onChange={(newVal: string) => {
                setHours(newVal);
              }}
              isPlaceholderPicker={isPlaceholderPicker}
              disabled={disabled}
            />
            <span className="separator">:</span>
            <TimerBlock
              isFocused={isFocused}
              testid="minutesInput"
              inputRef={minutesRef}
              value={minutes}
              type="minutes"
              step={step * 10}
              min={"00"}
              max={59}
              arrowNav={arrowNav}
              changeFocus={(key: string) => {
                if (!key.startsWith("Arrow") && minutes.length === 1) {
                  setTimeout(() => {
                    secondsRef.current?.focus();
                  }, 0);
                }
              }}
              onChange={(newVal: string) => {
                setMinutes(newVal);
              }}
              isPlaceholderPicker={isPlaceholderPicker}
              disabled={disabled}
            />
            <span className="separator">:</span>
            <TimerBlock
              isFocused={isFocused}
              testid="secondsInput"
              inputRef={secondsRef}
              value={seconds}
              type="seconds"
              step={step * 10}
              min={"00"}
              max={59}
              arrowNav={arrowNav}
              changeFocus={(key: string) => {
                if (!key.startsWith("Arrow") && seconds.length === 1) {
                  setTimeout(() => {
                    framesRef.current?.focus();
                  }, 0);
                }
              }}
              onChange={(newVal: string) => {
                setSeconds(newVal);
              }}
              isPlaceholderPicker={isPlaceholderPicker}
              disabled={disabled}
            />

            <>
              <span className="separator">:</span>
              <TimerBlock
                isFocused={isFocused}
                testid="framesInput"
                inputRef={framesRef}
                value={frames}
                type="frames"
                min={"00"}
                max={fps}
                step={1}
                arrowNav={arrowNav}
                changeFocus={(key: string) => key}
                onChange={(newVal: string) => {
                  setFrames(newVal);
                }}
                isPlaceholderPicker={isPlaceholderPicker}
                disabled={disabled}
              />
            </>
          </>
        </div>
        <div
          className={classNames("minusButton timingButton", { disabled })}
          onClick={() => {
            if (addTime) addTime();
          }}
        >
          <FontAwesomeIcon icon={faCirclePlus} />
        </div>
        <div
          className={classNames("plusButton timingButton", { disabled })}
          onClick={() => {
            if (deductTime) deductTime();
          }}
        >
          <FontAwesomeIcon icon={faCircleMinus} />
        </div>
      </div>
    </div>
  );
};

export default NewTimerPicker;
