import React from "react";
import EditorService from "../../services/EditorService";
import { useSlateStatic } from "@sumit-platforms/slate-react";
import _ from "lodash";
import { CustomEditor } from "../../types";

import "./RangeCount.scss";

interface Props {
  index?: number;
}

export const RangeCount = ({ index }: Props) => {
  const editor = useSlateStatic() as CustomEditor;
  if (!_.isNumber(index)) return null;
  const placeholderRangesCount = EditorService.getPlaceholderRangesCount(
    editor,
    index
  );
  return _.isNumber(index) ? (
    <div className={"RangeCount"} contentEditable={false}>
      {index - placeholderRangesCount}
    </div>
  ) : null;
};
