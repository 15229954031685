import React, { FC, useMemo } from "react";
import _ from "lodash";
import { useRecoilValue } from "recoil";
import classNames from "classnames";

import { RangeValidationResults } from "@sumit-platforms/types";

import { subtitlesValidationState } from "../../store/states/SubtitlesValidation";

import RangeValidation from "../RangeValidation/RangeValidation";

import "./RangeInfo.scss";

interface Props {
  validation: RangeValidationResults | undefined;
  text: string;
  isFocused: boolean;
  direction: "ltr" | "rtl";
  disabled?: boolean;
  children?: React.ReactNode;
}

const RangeInfo: FC<Props> = ({
  validation,
  text,
  isFocused,
  direction,
  disabled,
  children,
}) => {
  const subtitlesValidations = useRecoilValue(subtitlesValidationState);

  const maxCharsInLine = useMemo(
    () =>
      _.get(
        subtitlesValidations as any,
        "isMoreThanCharsInLine.maxCharsInLine"
      ),
    [subtitlesValidations]
  );
  const rangeLineCount = useMemo(() => {
    return text.split("\n").map((line) => {
      const lineCount = maxCharsInLine
        ? `${line.length}/${maxCharsInLine}`
        : line.length;
      return {
        lineCount,
        error: line.length > maxCharsInLine,
      };
    });
  }, [text]);

  // const shouldRenderLineCounter = useMemo(
  //   () => isFocused || _.some(rangeLineCount, (l) => l.error),
  //   [isFocused, rangeLineCount]
  // );

  return (
    <div
      className={classNames("SlateRangeInfo", direction, {
        disabled,
        redBg: !!validation,
      })}
      contentEditable={false}
    >
      <div className="charCounters">
        {_.map(rangeLineCount, (line, i) => {
          return (
            <div
              key={i}
              className={classNames("lineCount", { error: line.error })}
            >
              {line.lineCount}
            </div>
          );
        })}
      </div>
      {validation && (
        <div className="validations" contentEditable={false}>
          <RangeValidation validation={validation} direction={direction} />
        </div>
      )}
    </div>
  );
};

export default RangeInfo;
