import {
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
  format,
} from "date-fns";
import { get2DigitNumber, getTcTime } from "@sumit-platforms/ui-bazar/utils";

const getTimeStringFromSecs = (
  numOfSecs: number,
  onlyMins = false,
  withMilli = false,
  oneDigitHours = false
): string => {
  const stringifiedNumber = numOfSecs.toString();
  const [secondsStr, milisecondsStr] = stringifiedNumber.split(".");
  const fixedNumber = Number(
    milisecondsStr ? `${secondsStr}.${milisecondsStr.slice(0, 3)}` : secondsStr
  );

  const milliseconds = (fixedNumber % 1).toFixed(3).substring(2);
  const seconds = fixedNumber % 60;
  const minutes = (fixedNumber / 60) % 60;
  const hours = fixedNumber / 3600;

  if (onlyMins)
    return (
      get2DigitNumber(fixedNumber / 60) +
      ":" +
      get2DigitNumber(fixedNumber % 60) +
      (withMilli ? "." + milliseconds : "")
    );

  return (
    get2DigitNumber(hours, oneDigitHours) +
    ":" +
    get2DigitNumber(minutes) +
    ":" +
    get2DigitNumber(seconds) +
    (withMilli ? "." + milliseconds : "")
  );
};

const getTCfromSeconds = (seconds: number): string => {
  const notMoreThanDaySeconds = seconds % (60 * 60 * 24);
  const tc = getTimeStringFromSecs(notMoreThanDaySeconds);

  return tc;
};

const getTimeNumberFromString = (time: string): number => {
  const [hours = 0, minutes = 0, seconds = 0] = time.split(":");
  const hourSeconds = Number(hours) * 3600;
  const minutesSeconds = Number(minutes) * 60;

  return hourSeconds + minutesSeconds + Number(seconds);
};

const _get2DigitNumber = (n: number): number | string => {
  const floored = Math.floor(n);
  if (floored < 10) return `0${floored}`;
  return floored;
};

const getDateString = (date: Date): string => {
  return (
    _get2DigitNumber(date.getDate()) +
    "/" +
    _get2DigitNumber(date.getMonth() + 1) +
    "/" +
    _get2DigitNumber(date.getFullYear())
  );
};

const compareDates = (a: Date, b: Date): number => {
  if (a.getFullYear() !== b.getFullYear())
    return a.getFullYear() - b.getFullYear();

  if (a.getMonth() !== b.getMonth()) return a.getMonth() - b.getMonth();

  if (a.getDate() !== b.getDate()) return a.getDate() - b.getDate();

  return 0;
};
const getDeadlineVerbalString = (delivery: Date, t: any): any => {
  const deliveryDate = new Date(delivery);
  let hours = differenceInHours(deliveryDate, Date.now());
  const minutes = differenceInMinutes(deliveryDate, Date.now());
  if (hours <= 0 && minutes <= 0) return "0";
  const days = differenceInDays(deliveryDate, Date.now());
  const daysWhenTheHourDivideBy24 = hours / 24;
  hours = hours % 24;
  if (days && hours) {
    return `${days} ${t("days")}, ${hours} ${t("hours_shortcut")}`;
  } else if (!days && hours) {
    return hours <= 2 ? t("two_hours") : `${hours} ${t("hours")}`;
  } else if (!hours && days) {
    return `${daysWhenTheHourDivideBy24} ${t("days")}`;
  } else {
    return `${minutes % 60} ${t("minuts")}`;
  }
};

const getDeadlineString = (deadline: Date): string => {
  return format(deadline, "dd/MM/yy HH:SS");
};

const getDeadlineStr = (deadline: Date): string => {
  const hours = differenceInHours(deadline, Date.now());
  if (0 >= hours) return "0";
  const minutes = differenceInMinutes(deadline, Date.now()) % 60;
  let hoursZero = "";
  let minutesZero = "";
  if (hours < 10 && hours >= 0) hoursZero = "0";
  if (minutes < 10 && minutes >= 0) minutesZero = "0";
  const time = hoursZero + hours + ":" + minutesZero + minutes;
  return time;
};
const getDeadlineStatus = (deadline: Date): string => {
  const hours = differenceInHours(deadline, Date.now());
  if (hours <= 12) return "high";
  const className = hours >= 243 ? "low" : "medium";
  return className;
};

const getFixedFrameRateTime = ({
  time,
  frameLength,
  timeToAdd = 0,
  operator,
}: {
  time: number;
  frameLength: number;
  timeToAdd?: number;
  operator?: "add" | "subtract";
}): number => {
  if (time + timeToAdd < 0) {
    return 0;
  }
  let fixedTime = Math.round((time + timeToAdd) * 1000) / 1000;
  const remainder =
    (Math.round(fixedTime * 1000) % (frameLength * 1000)) / 1000;

  if (!remainder) {
    return fixedTime;
  }

  if (operator) {
    fixedTime =
      operator === "add" ? time + (timeToAdd - remainder) : time - remainder;
  } else {
    const fixedTimeToAdd = remainder < frameLength / 2 ? remainder : -remainder;
    fixedTime = time + fixedTimeToAdd;
  }

  return Math.round(fixedTime * 1000) / 1000;
};

const addFramesToTime = ({
  time,
  frameLength,
  framesToAdd,
}: {
  time: number;
  frameLength: number;
  framesToAdd: number;
}): number => {
  const frames = Math.round((time / frameLength) * 1000) / 1000;
  const updatedFrames = frames + framesToAdd;

  const fixedFPSFrames =
    framesToAdd > 0 ? Math.floor(updatedFrames) : Math.ceil(updatedFrames);

  return fixedFPSFrames * frameLength;
};

export default {
  getDeadlineStatus,
  getDeadlineStr,
  getTimeStringFromSecs,
  getTCfromSeconds,
  getTimeNumberFromString,
  getDateString,
  getDeadlineVerbalString,
  getDeadlineString,
  getFixedFrameRateTime,
  addFramesToTime,
  compareDates,
};
