import { CustomEditor, CustomElement } from "../types";
import EditorService from "../services/EditorService";
import _ from "lodash";

export const withRangeBreakOrMerge = (editor: CustomEditor) => {
  const { apply } = editor;

  editor.apply = (operation) => {
    if (operation.type === "split_node") {
      apply(operation);

      if (_.isEmpty(operation.properties)) return;

      EditorService.onBreakRange(operation.path, editor);

      return;
    }

    if (operation.type === "merge_node") {
      const isAllowed = EditorService.onBeforeMergeRange(editor);
      if (!isAllowed) return;

      const oldElements = editor.children as CustomElement[];
      apply(operation);

      if (_.isEmpty(operation.properties)) return;

      EditorService.onMergeRange(operation, editor, oldElements);

      return;
    }

    // For all other operations, apply them normally
    apply(operation);
  };

  return editor;
};
